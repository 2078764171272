import React from "react"
import Layout from "../components/Layout"
import big from "../assets/images/bgmain.jpg"
import styled from "styled-components"
import "bootstrap/dist/css/bootstrap.min.css"
import { Container, Row, Col } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

//para espacio de spacer hacer pequeno el background
const spacer = 20

//para agrandamiento de segundo porcentaje de background
const percentage = 200

const Franja = styled.div`
  background: black;
  height: 60px;
`

const TituloBox = styled.div`
  font-size: 38px;
  font-weight: 700;
  font-family: "Titillium Web";
  letter-spacing: -2px;
  color: white;
`
const MensajeBox = styled.div`
  font-size: 58px;
  font-weight: 700;
  font-family: "Titillium Web";
  letter-spacing: -2px;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
  @media (max-width: 768px) {
    font-size: 30px;
  }
`

const LeftBox = styled.div`
  font-size: 25px;
  font-weight: 500;
  font-family: "Titillium Web";
  letter-spacing: -2px;
  /* text-align: center; */
  padding-top: 50px;
  padding-bottom: 50px;
`

const RightBox = styled.div`
  font-size: 25px;
  font-weight: 500;
  font-family: "Titillium Web";
  /* letter-spacing: -2px; */
  /* text-align: center; */
  padding-top: 50px;
  padding-bottom: 50px;
`
const Boldie = styled.div`
  font-weight: bold;
`

const SitiosWeb = () => {
  return (
    <Layout sbiggo={big} spacer={spacer} percentage={percentage}>
      <Franja>
        <Container>
          <Row>
            <Col>
              <TituloBox>Sitios Web</TituloBox>
            </Col>
          </Row>
        </Container>
      </Franja>
      <Container>
        <Row>
          <Col>
            <MensajeBox>
              Creamos sitios web con tecnología de punta y calidad total
            </MensajeBox>
          </Col>
        </Row>
      </Container>
      <StaticImage
        src="../assets/images/green1.jpg"
        alt="web"
        aspectRatio={26 / 9}
      />
      <Container>
        <Row>
          <Col md={3} className="d-none d-lg-block">
            <LeftBox>
              <Boldie>Contáctenos: </Boldie>
              <br /> info@yaku.com.ec <br />
              +593999944405
            </LeftBox>
          </Col>
          <Col md={9} xs={12} className="d-lg-block">
            <RightBox>
              En Yaku Tecnología hemos creado soluciones web de calidad por más
              de 10 años. El proceso que realizamos consiste en los siguientes
              pasos: <br /> <br /> <br />
              1. Acercamiento con el Cliente para conocer su necesidad y su
              visión del sitio web que requiere implementar <br /> <br />
              2. Con la data recolectada preparamos un informe preliminar en
              donde realizamos un análisis completo de su marca y realizamos
              recomendaciones en aspectos como: tecnología a utilizar, diseño
              personalizado a implementar, alcance del desarrollo, otros <br />
              <br />
              3. Creamos un sitio web de pruebas donde el Cliente puede
              verificar los avances del proyecto y solicitar cambios o mejoras
              <br /> <br />
              4. Implementamos un diseño personalizado para cada proyecto. No
              utilizamos templetas <br /> <br /> 5. Una vez el sitio web ha sido
              creado en sitio de pruebas lo implementamos en el hosting del
              Cliente o en el servidor de preferencia <br /> <br />
              6. Luego de ser publicado el sitio web generamos un soporte 24/7
              del nuevo sitio con el fin de monitorear, realizar cambios y otros
              de caracter técnico <br /> <br /> <br />
              El sitio web entregado tiene las siguientes prestaciones: <br />
              <br /> <br />
              1. Es responsive, se adapta a cualquier dispositivo móvil <br />
              <br />
              2. Tiene diseño personalizado, único para su marca <br /> <br />
              3. Es rápido y de fácil acceso <br /> <br />
              4. Es seguro gracias a los componentes de alta seguridad que
              utilizamos <br /> <br />
              5. Es interconectado, nos aseguramos que todas sus redes sociales
              mantegan enlaces hacia y desde el sitio web <br /> <br />
              <br />
              <Boldie>Experiencia</Boldie>
              <br /> <br />
              Hemos creado soluciones para empresas líderes del sector privado.
              También trabajamos con proyectos del sector público. Con mucho
              gusto le podemos hacer llegar nuestro portafolio de proyectos
              creados. Contáctenos pronto!
              <br /> <br />
              Mantenemos una amplia cartera de clientes que han confiado en
              nuestro trabajo. Hemos creado sitios web, aplicaciones web,
              aplicaciones sociales, entre otros. <br />
              <br />A través de los años hemos incrementado el uso de nuevas
              tecnologías que le permitan a nuestros clientes poder solventar
              cualquier requerimiento sin importar el grado de complejidad.
              <br />
              <br />
              ¿Tiene un nuevo proyecto? Permítanos ayudarle a conseguir los
              objetivos planificados. Nuestro soporte es personalizado, somos
              parte de su equipo y nos comprometemos a obtener las metas de cada
              proyecto. <br />
              <br />
              <br />
            </RightBox>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default SitiosWeb
